@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

.side-bar .side-bar__inner {
  background-color: #132335;
}

.side-bar .layer-panel__header {
  background-color: #1b3147;
}

.kepler-gl .map-control .map-control-button {
  background-color: #132335;
  border-color: #132335;
}

.kepler-gl .side-bar__inner .typeahead {
  background-color: #132335;
  border-color: #132335;
}

.kepler-gl .side-bar__inner .item-selector__dropdown,
.kepler-gl .side-bar__inner .chickleted-input,
.kepler-gl .side-bar__inner .list-selector,
.kepler-gl .side-bar__inner .color-selector__selector,
.kepler-gl .side-bar__inner input,
.kepler-gl .side-bar__inner .type-dark,
.kepler-gl .side-bar__inner .filter-panel__content,
.kepler-gl .side-bar__inner .filter-panel__header,
.kepler-gl .side-bar__inner .interaction-panel__header,
.kepler-gl .side-bar__inner .interaction-panel__content,
.kepler-gl .side-bar__inner .interaction-panel__content .chickleted-input > div,
.kepler-gl .side-bar__inner .map-dropdown-option,
.kepler-gl .side-bar__inner .side-panel-panel__content,
.kepler-gl .side-bar__inner .kg-range-slider__slider .kg-range-slider.chickleted-input,
.map-popover {
  background-color: #1b3147 !important;
  border-color: #1b3147 !important;
}

.kepler-gl .geocoder-input input,
.kepler-gl .side-bar__inner .filter-panel__content input,
.kepler-gl .side-bar__inner .filter-panel__content .chickleted-input,
.kepler-gl .side-bar__inner .interaction-panel__content .chickleted-input,
.kepler-gl .side-bar__inner .filter-panel__content .item-selector__dropdown,
.kepler-gl .side-bar__inner .interaction-panel__content .item-selector__dropdown,
.kepler-gl .panel-header-dropdown__inner .toolbar-item {
  background-color: #132335 !important;
  border-color: #132335 !important;
}


::placeholder {
  font-size: 11px!important;
}