.report {
    min-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.titleInput::placeholder {
    color: white!important;
    opacity: 0.7;
}
.title .titleInput {
    color: white;
    font-size: 16px;
    text-align: center;
    background-color: transparent;
}

.titleText {
    color: white;
    display: block;
    font-size: 16px;
    padding: 5px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.titleEditIcon {
    opacity: 0;
    padding: 2px 7px;
    color: white;
    font-size: 0.8em;
}

.titleTextEdit .titleEditIcon {
    opacity: 0.4;
}

.titleTextEdit {
    cursor: pointer;
}

.title {
    width:100%;
    min-width: 100px;
    text-align: center;
    position: relative;
    /* flex: 1px; */
}

.body {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.tabs {
    background-color: #EDEDED;
    background-color: #F7F7F7;
}

.tabs :global .ant-tabs-nav {
    margin-bottom: 0!important;
}

.querySection {
    width: 500px;
    display: flex;
    flex-direction: column;
}

.keplerBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.keplerFlex {
    flex: 1;
    position: relative;
    background-color: #15232F;
}


.keplerBlock :global #kepler-gl__kepler .side-bar {
    box-shadow: 0 10px 20px rgb(0 0 0 / 40%) !important;
    /* box-shadow: none; */
    /* color: #F00; */
}

/* .keplerBlock :global #kepler-gl__kepler .side-panel__header__top {
    display: none;
} */

.keplerBlock :global #kepler-gl__kepler .add-data-button {
    display: none;
}

.keplerBlock :global #kepler-gl__kepler .remove-dataset {
    display: none;
}

.keplerBlock :global #kepler-gl__kepler .map-control-button[data-for=locale] {
    display: none;
}

/* side-panel__panel-header side-side-panel__header */
.keplerBlock :global #kepler-gl__kepler .side-side-panel__header {
    background-color: #1B3047;
}

/* side-side-panel__header__bottom */
.keplerBlock :global #kepler-gl__kepler .side-side-panel__header__bottom {
    background-color: #1B3047;
}


/* side-side-panel__header__bottom
.keplerBlock :global #kepler-gl__kepler .add-filter-button {
    background-color: #F7921E;
} */


.readOnly span {
    margin-right: 5px;
}
.readOnly {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #6C7483;
    padding-right: 10px;
    font-style: italic;
    vertical-align: middle;
    cursor: pointer;
}

.tabIcon {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 8px;
    margin-left: 2px;
    color: #6A7484 !important;
    font-size: 0.9em;
}

.query {
    display: none;
}

.activeQuery {
    flex: 1;
    display: flex;
}

.keplerError {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
}

.expandButton {
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 16%);
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 0;
    background-color: #29323C;
    background: #29323C;
    color: #6A7485;
    border: 0;
    z-index: 99;
    min-height: 32px;
    line-height: 38px;
}

[data-tippy-root] {
    z-index: 999999!important
}

#next-control {
    display: none
}