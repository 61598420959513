.query {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
}

.editor {
    flex: 1;
}

.editor :global .ace_content,
.editor :global .ace_gutter {
    /* background-color: #F7F7F7!important; */
    background-color: white!important;
}

.editor :global .ace_gutter-active-line.ace_gutter-cell {
    background-color: #EDEDED!important;
}

.status {
    word-break: break-all;
}
.status {
    flex: 1;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    min-height: 40px;
}

.statusHead {
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    margin-left: 10px;
}

.icon {
    font-size: 18px;
    margin-right: 10px;
}

.message {
    font-size: 1.1em;
    flex: 1;
}

.queryStatus{
    padding: 10px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgb(217, 217, 217);
}

.success {
    background-color: #fff6eb;
    border-top-color: #eb8c23;
}

.error {
    background-color: #FFF2F0;
    border-top-color: #FFCCC7;
}

.errorMessage {
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
    font-size: 0.85em;
    margin-left: 28px;
}

.jobTimer {
    margin-right: 10px;
}

.processed {
    font-size: 0.8em
}

.dataDoc {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 10;
    text-align: center;
}