// https://ant.design/docs/react/customize-theme
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

html, body, #root {
    width: 100%;
    min-height: 100%;
}

@font-family: 'Lato', Roboto, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@primary-color: #eb8c23; // primary color for all components
@link-color: #eb8c23; // link color
@success-color: #eb8c23; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #1B3047; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers


.results-table-wrapper .col-name button {
  background-color: transparent!important;
  color: #000!important;
  background: transparent;
}

.results-table-wrapper .col-name+div {
  position: absolute;
  bottom: 5px;
}

.results-table-wrapper .header-cell {
  border: 1px solid #ddd;
}

#next-control {
  display: none
}

.geocoder-results {
  top: 33px !important
}

.geocoder-results .geocoder-item.active, .geocoder-results .geocoder-item:hover{
  color: #fff!important
}

// .gm-compass > img {
//   display: none!important
// }

.gm-bundled-control button {
  background: transparent!important;
}