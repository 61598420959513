:global(.ant-menu-item) a.dekartLink {
    color: #57B7D3!important;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.dekartMenu :global(.ant-menu) {
    background-color: transparent !important;
}

:global(.ant-menu-submenu) :global(.ant-menu){
    background-color: #2B323B !important;
}

.dekartMenu :global(.ant-menu-item-selected),
.dekartMenu :global(.ant-menu-item-active) {
    background-color: transparent !important;
}

.dekartMenu {
    flex: 1;
}