.homePage {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
}

.body {
    padding: 20px;
    display: flex;
    flex: 1;
    overflow: scroll;
    align-items: center;
    flex-direction: column;
}

.dataDocs {
    display: block;
}

.deleteColumn {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.reportsRow .deleteButton {
    opacity: 0;
    pointer-events: none;
}

.reportsRow:hover .deleteButton {
    opacity: 1;
    pointer-events: all;
}

.titleColumn {
    width: 600px;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.reports {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.reportsHeader{
    margin-bottom: 20px;
}

.newReleaseTitle {
    font-size: 18px;
    margin-bottom: 10px;
}

.newReleaseIcon {
    color: #57B7D3!important;
    font-size: 42px;
    position: absolute;
    left: 20px;
}
.newRelease {
    background-color: white;
    width: 712px;
    padding: 20px;
    padding-left:  82px;
    margin-bottom: 40px;
    position: relative;
    box-shadow: 0 5px 9px -1px #B1EDFF;
}